import React from 'react';
import './Faq.scss';
import Question from '../../components/faq-components/Question';
import questionData from './questionData';


function Faq({t}) {
    const questionItem = questionData(t).map((item,index) => (
        <Question t={t} key={index} data={item}/>
    ))
  return (
    <div className="faq-wrapper">
        <div className="faq-content-wrapper">
            <div className="faq-capture">
                {t("faq_captture")}
            </div>
                {questionItem}
        </div>
    </div>
  );
}

export default Faq;