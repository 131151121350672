import React from 'react';
import './ServicesItem.scss';
import Button from '../button/Button';

function ServicesItem({t,handlerSelectModal,data}) {
    

    const servicesDataItem = data(t).map((item,index) => (
        <div key={index} className={`services-block-item-wrapper`}>
            <div  className="services-block-content">
                <div className="services-block-item">
                    <div className="services-block-capture">
                        {item.capture}
                    </div>
                    
                    <div className="services-block-explanation">
                        
                        {item.explanation}
                    </div>
                </div>
                <div className="services-block-item">
                    <div className="mob-capture">
                        {t("services_main_item_description2")}
                    </div>
                    {item.base.map((i,index) => (
                        <div key={index} className='base-item' >{i}</div>
                    ))}
                </div>
                <div className="mob-capture">
                    {t("services_main_item_description3")}
                </div>
                
                <div className="services-button-wrapper">
                    <Button text={t("Services_button")} handlerSelectModal={handlerSelectModal}/>
                </div>
            </div>
        </div>
    ));

  return (
    <>
       {servicesDataItem}
    </>
  );
}

export default ServicesItem;