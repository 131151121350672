import React from 'react';
import './Question.scss';
import minus from "../../assets/img/faqArrowUp.png";
import plus from  "../../assets/img/faqArrowDown.png";
import { useState } from 'react';
import { useInView } from 'react-intersection-observer';

function Question({t,data}) {

    const [open,setOpen] = useState(false);
    const [active,setActive] = useState();

    const{ ref,inView } = useInView({
        threshold:0,
      });

    const openAnswer = () => {
        setOpen(!open);
    };

    const hadnleActive = () => {
        setActive(!active)
    };

    const [changeImage,setChangeImage]=useState(plus);

  return (
    <div className="question-main">
        <div className="question-wrapper">
            <div className="question-content" onClick={()=>{
                        openAnswer();
                        hadnleActive();
                        changeImage === plus ? setChangeImage(minus): setChangeImage(plus);
                    }}>
                <div ref={ref} className={`question-item ${inView ? 'question-item-animation-active' : ''}`}>
                    <div className={`question ${active ? 'question-item-active' : ''}`}>
                        {data.question}
                    </div>
                    <div className="question-img" >
                        <img src={changeImage} alt="" />
                    </div>
                </div>
                <div className={`${"answer"} ${open? 'answer-active' : ''}`}>
                    {data.answer}
                </div>
            </div>    
        </div>
    </div>
  );
}

export default Question;