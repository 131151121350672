import React from 'react';
import Hero from '../../hero/Hero';
import Services from '../../services/Services';
import Results from '../../results/Results';
import Clients from '../../clients/Clients';
import Offer from '../../offer/Offer'; //calc block if needed
import Advantages from '../../advantages/Advantages';
import Reviews from '../../reviews/Reviews';
import Team from '../../team/Team';
import Faq from '../../faq/Faq';
import MapBlock from '../../map-block/MapBlock';
import B2b from '../../b2b/B2b';

function HomePage({ t, handlerSelectModal, onFormFail, onFormSuccess }) {
  return (
    <>
      <Hero t={t} handlerSelectModal={handlerSelectModal} />
      <Services t={t} handlerSelectModal={handlerSelectModal} />
      <Results t={t} />
      <Clients t={t} />
      {/*<B2b t={t} />*/}
      <Advantages t={t} />
      <Reviews t={t} />
      <Team t={t} />
      <Faq t={t} />
      <MapBlock t={t} />
      
     
    </>
  );
}

export default HomePage;