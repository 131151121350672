import React from 'react';
import './Team.scss';
import teamImg from '../../assets/img/b2b.svg';
import { useInView } from 'react-intersection-observer';

function Team({t}) {

    const{ ref,inView } = useInView({
        threshold:0,
    });

    const jobTitleData = [
        {
            name:"Oleksandra Shkola",
            Jobtitle:t("team_jobtittle2")
        },
       
        {
            name:"Svitlana Kovel",
            Jobtitle:t("team_jobtittle2")
        },
        {
            name:"Darya Arlova",
            Jobtitle:t("team_jobtittle2")
        },
        {
            name:"Anastasiia Shevchuk",
            Jobtitle:t("team_jobtittle2")
        },
        {
            name:'Marek Soszka',
            Jobtitle:t("team_jobtittle1")
        },
    ];
    const jobItem = jobTitleData.map((item) => (
        <div key={item.name} className="job-item">
            <div className="job-item-name">
                {item.name}
            </div>
            <div className="job-item-jobTittle">
                {item.Jobtitle}
            </div>
        </div>
    ))
  return (
    <div id='team' className="team-wrapper">
        <div className="team-wrapper-content">
        <div ref={ref} className={`team-content ${inView ? 'team-content-active' : ''}`}>
            <div className="team-capture">
                {t("team_capture")}
            </div>
            <div className="job-title-wrapper">
                {jobItem}
            </div>
            <div className="team-image">
                <img src={teamImg} alt="team-img" />
            </div>
        </div>
        </div>
    </div>
  );
}

export default Team;