import React from 'react';
import './Advantages.scss';
import adImg1 from '../../assets/img/adImg1.svg';
import adImg2 from '../../assets/img/adImg2.svg';
import adImg3 from '../../assets/img/adImg3.svg';
import adImg4 from '../../assets/img/adImg4.svg';
import adImg5 from '../../assets/img/adImg5.svg';
import { useInView } from 'react-intersection-observer';

function Advantages({t}) {

    const{ ref,inView } = useInView({
        threshold:0,
      });

    const cardDataCentr = [
        {   
            img:adImg1,
            capture:t("advantages_item_capture1"),
            description:t("advantages_item_description1")
        },
        {   
            img:adImg2,
            capture:t("advantages_item_capture2"),
            description:t("advantages_item_description2")
        },
        {   
            img:adImg3,
            capture:t("advantages_item_capture3"),
            description:t("advantages_item_description3")
        },
    ];

    const cardDataBottom = [
        {   
            img:adImg4,
            capture:t("advantages_item_capture4"),
            description:t("advantages_item_description4")
        },
        {   
            img:adImg5,
            capture:t("advantages_item_capture5"),
            description:t("advantages_item_description5")
        },
    ]

    const cardCentrItem = cardDataCentr.map((item,index) => (
        <div key={index} className={`advanages-card-item ${inView ? 'advanages-card-item-active' : ''}`}>
            <div className="advanages-text-block">
                <div className="advanages-img"><img src={item.img} alt="" /></div>
                <div className="advanages-text-wrapper">
                    <div className="advanages-item-capture">{item.capture}</div>
                    <div className="advanages-item-description">{item.description}</div>
                </div>
            </div>
        </div>
    ));

    const cardBottomItem = cardDataBottom.map((item,index) => (
        <div key={index} className={`advanages-card-item-bottom ${inView ? 'advanages-card-item-bottom-active' : ''}`}>
            <div className="advanages-text-block">
                <div className="advanages-img"><img src={item.img} alt="" /></div>
                <div className="advanages-text-wrapper">
                    <div className="advanages-item-capture">{item.capture}</div>
                    <div className="advanages-item-description">{item.description}</div>
                </div>
            </div>
        </div>
    ));

  return (
    <div id='advantages' className="advanages-wrapper-position">
        <div className="advanages-wrapper">
            <div ref={ref} className="advanages-content-wrapper">
                <div className="advanages-content">
                    <div className="advanages-capture">
                        {t("advantages_capture")}
                    </div>
                    <div className="advanages-card-wrapper-center">
                        {cardCentrItem}
                    </div>
                    <div className="advanages-bottom-wrapper">
                        <div className="advanages-card-wrapper-bottom">
                            {cardBottomItem}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Advantages;