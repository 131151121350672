import React from 'react';
import './PopUpForm.scss';
import Form from '../../form/Form';

function PopUpForm({t,handlerSelectModal,onFormFail, onFormSuccess}) {
  return (
    <div className="main-form-wrapper">
        <div className="main-form-content">
            <div className="main-form-header">
                {t("popup_capture")}
            </div>
            <div className="main-form-form">
            <Form t={t} textButton={t("form_button")} handlerSelectModal={handlerSelectModal} onFormSuccessSubmit={onFormSuccess} onFormFailSubmit={onFormFail}/>
            </div>
            
        </div>
    </div>
  );
}

export default PopUpForm;