import React from 'react';
import './Privacy.scss';


function Privacy() {
  return (
    <div className="privacy-main-wrapper">
        <div className="privacy-header">
            <div className="privacy-capture">
                Polityka prywatności
            </div>
        </div>
        <div className="privacy-text">
            <div className="privacy-description">
                Niniejsza Polityka Prywatności określa zasady przechowywania, przetwarzania i dostępu do informacji na stronie www oraz urządzeniach Użytkownika, służących do realizacji usług świadczonych drogą elektroniczną.
            </div>
            <h2>Definicje</h2>
            <ol>
                <li> „Administrator” oznacza osobę fizyczną lub prawną, organ publiczny, jednostkę lub inny podmiot, który samodzielnie lub wspólnie z innymi ustala cele i sposoby przetwarzania danych osobowych; jeżeli cele i sposoby takiego przetwarzania są określone w prawie Unii lub w prawie państwa członkowskiego, to również w prawie Unii lub w prawie państwa członkowskiego może zostać wyznaczony administrator lub mogą zostać określone konkretne kryteria jego wyznaczania.</li>
                <li> „Dane osobowe” oznaczają wszelkie informacje o zidentyfikowanej lub możliwej do zidentyfikowania osobie fizycznej („osobie, której dane dotyczą”); możliwa do zidentyfikowania osoba fizyczna to osoba, którą można bezpośrednio lub pośrednio zidentyfikować, w szczególności na podstawie identyfikatora takiego jak imię i nazwisko, numer identyfikacyjny, dane o lokalizacji, identyfikator internetowy lub jeden bądź kilka szczególnych czynników określających fizyczną, fizjologiczną, genetyczną, psychiczną, ekonomiczną, kulturową lub społeczną tożsamość osoby fizycznej.</li>
                <li>„Przetwarzanie” oznacza operację lub zestaw operacji wykonywanych na danych osobowych lub zestawach danych osobowych w sposób zautomatyzowany lub niezautomatyzowany, taką jak zbieranie, utrwalanie, organizowanie, porządkowanie, przechowywanie, adaptowanie lub modyfikowanie, pobieranie, przeglądanie, wykorzystywanie, ujawnianie poprzez przesłanie, rozpowszechnianie lub innego rodzaju udostępnianie, dopasowywanie lub łączenie, ograniczanie, usuwanie lub niszczenie.</li>
                <li>„Strona trzecia” oznacza osobę fizyczną lub prawną, organ publiczny, jednostkę lub podmiot inny niż osoba, której dane dotyczą, administrator, podmiot przetwarzający czy osoby, które – z upoważnienia administratora lub podmiotu przetwarzającego – mogą przetwarzać dane osobowe.</li>
                <li>„Zgoda” osoby, której dane dotyczą oznacza dobrowolne, konkretne, świadome i jednoznaczne okazanie woli, którym osoba, której dane dotyczą, w formie oświadczenia lub wyraźnego działania potwierdzającego, przyzwala na przetwarzanie dotyczących jej danych osobowych.</li>
                <li>„Naruszenie ochrony danych osobowych” oznacza naruszenie bezpieczeństwa prowadzące do przypadkowego lub niezgodnego z prawem zniszczenia, utracenia, zmodyfikowania, nieuprawnionego ujawnienia lub nieuprawnionego dostępu do danych osobowych przesyłanych, przechowywanych lub w inny sposób przetwarzanych.</li>
                <li>„Cookies” - oznacza dane informatyczne, w szczególności niewielkie pliki tekstowe, zapisywane i przechowywane na urządzeniach za pośrednictwem których Użytkownik korzysta ze stron internetowych Serwisu.</li>
                <li>„Cookies Administratora” - oznacza Cookies zamieszczane przez Administratora, związane ze świadczeniem usług droga elektroniczną przez Administratora za pośrednictwem Serwisu.</li>
                <li>„Cookies Zewnętrzne” - oznacza Cookies zamieszczane przez partnerów Administratora, za pośrednictwem strony internetowej Serwisu.</li>
                <li>„Serwis” - oznacza stronę̨ internetową, pod którą Administrator prowadzi serwis internetowy, działającą w domenie www.pls.net.pl</li>
                <li> „Urządzenie” - oznacza elektroniczne urządzenie za pośrednictwem, którego Użytkownik uzyskuje dostęp do stron internetowych Serwisu.</li>
                <li>„Użytkownik” - oznacza podmiot, na rzecz którego zgodnie z Regulaminem i przepisami prawa mogą̨ być świadczone usługi drogą elektroniczną lub z którym zawarta może być́ Umowa o świadczenie usług drogą elektroniczną.</li>
            </ol>
            <h2>Administrator danych osobowych</h2>
            <ol>
                <li>Administratorem Pani/Pana danych osobowych jest Personal Legal Service sp. z o. o.,  ul. Swobodna 1, 50-088 Wrocław, NIP 8943035648, REGON 020839141, adres email: biuro@plspoland.pl</li>
                <li>Cel przetwarzania danych osobowych: 
                    <ul>
                        <li>a. Pani/Pana dane osobowe zebrane w formularzu będą przetwarzane w celu przeprowadzenia legalizacji pobytu na terytorium Rzeczpospolitej Polskiej zgodnie zakresem danych podanym w art. 13 Ustawy o cudzoziemcach z dnia 12 grudnia 2013 r. (Dz.U. 2013 poz. 1650), podstawą prawną przetwarzania jest Pani/Pana danych jest zgoda – (art. 6 ust. 1 lit. a RODO). </li>
                        <li>b. Pani/Pana dane osobowe będą przetwarzane w celu rekrutacji w ramach własnych ofert pracy administratora danych, podstawą prawną przetwarzania danych, wskazanych w przepisach Kodeksu pracy oraz Kodeksie cywilnym, jest nawiązanie i realizacja umowy – (art. 6 ust. 1 lit. b RODO). </li>
                        <li>c. Pani/Pana dane osobowe będą przetwarzane w celu zatrudnienia w ramach własnych rekrutacji prowadzonych przez administratora danych (podstawą prawną jest art. 22(1) Kodeksu pracy oraz art. 734 Kodeksu cywilnego). </li>
                        <li>d. Pani/Pana dane osobowe będą przetwarzane w celu realizacji Pani/Pan praw (podstawą prawną przetwarzania jest prawnie uzasadniony interes administratora - art. 6 ust. 1 lit. f, RODO). </li>
                        <li>e. Pani/Pana dane osobowe będą przetwarzane w celu przesyłania treści marketingowych związanych z ofertami pracy, podstawą prawną przetwarzania jest Pani/Pana danych jest zgoda – (art. 6 ust. 1 lit. a RODO). </li>
                        <li>f. Jeśli wyraziła Pani/Pana zgodę na przetwarzanie danych osobowych w formie plików Cookies ‘ciasteczek’w celu spersonalizowania, zapamiętania przez stronę Pani/Pan preferencji i ustawień tj.: login i hasło, adres IP, data, godzina logowania. Podstawą przetwarzania będzie art. 173, Pr. telekomunikacyjnego. Pani/Pana dane osobowe przechowywane będą do momentu odwołania zgody.</li>
                    </ul>
                </li>
                <li>Pani/Pana dane osobowe będą udostępniane podmiotom trzecim w celu świadczenia na rzecz Personal Legal Service usługi wsparcia, w tym w szczególności: usługi księgowe, prawne, windykacyjne, IT, przy czym powołane podmioty będą przetwarzać Pani/Pana dane na podstawie zawartych umów i wyłącznie na jej udokumentowane polecenie.</li>
                <li>Poza ww. podmiotami Pani/Pana dane osobowe mogą być przekazane wyłącznie na żądanie podmiotów upoważnionych do tego na podstawie przepisów prawa.</li>
                <li>Ww. zgody mogą zostać odwołane w każdym czasie przez wysłanie oświadczenia na adres biura podmiotu: ul. Swobodna 1, 50-088 Wrocław , NIP 8943035648, REGON 020839141 Pocztą Polską lub na adres email: biuro@plspoland.pl</li>
                <li>Wycofanie zgód nie ma wpływu na zgodność z prawem przetwarzania, którego dokonano przed jej wycofaniem.</li>
                <li>Posiada Pani/Pan prawo do żądania od administratora dostępu do danych osobowych, ich sprostowania, usunięcia, przeniesienia danych lub ograniczenia przetwarzania, a także prawo do cofnięcia zgody na przetwarzanie w zakresie w jakim przetwarzanie odbywało się lub odbywa na podstawie Pani/Pana zgody oraz prawo wniesienia sprzeciwu, co do przetwarzania.</li>
                <li>Ma Pani/Pan prawo wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych, ul. Stawki 2, 00-193 Warszawa.</li>
            </ol>
            <h2>Rodzaje wykorzystywanych cookies</h2>
            <ol>
                <li>Stosowane przez Administratora Cookies są̨ bezpieczne dla Urządzenia Użytkownika. W szczególności tą drogą nie jest możliwe przedostanie się̨ do Urządzeń́ Użytkowników wirusów lub innego niechcianego oprogramowania lub oprogramowania złośliwego. Pliki te pozwalają̨ zidentyfikować oprogramowanie wykorzystywane przez Użytkownika i dostosować Serwis indywidualnie każdemu Użytkownikowi zgodnie z umową. Cookies zazwyczaj zawierają̨ nazwę̨ domeny, z której pochodzą̨, czas przechowywania ich na Urządzeniu oraz przypisaną wartość.</li>
                <li>Administrator wykorzystuje dwa typy plików cookies: 
                    <ul>
                        <li>a. Cookies sesyjne: są̨ przechowywane na Urządzeniu Użytkownika i pozostają̨ tam do momentu zakończenia sesji danej przeglądarki. Zapisane informacje są̨ wówczas trwale usuwane z pamięci Urządzenia. Mechanizm cookies sesyjnych nie pozwala na pobieranie jakichkolwiek danych osobowych ani żadnych informacji poufnych z Urządzenia Użytkownika; </li>
                        <li>b. Cookies trwałe: są̨ przechowywane na Urządzeniu Użytkownika i pozostają̨ tam do momentu ich skasowania. Zakończenie sesji danej przeglądarki lub wyłączenie Urządzenia nie powoduje ich usunięcia z Urządzenia Użytkownika. Mechanizm cookies trwałych nie pozwala na pobieranie jakichkolwiek danych osobowych ani żadnych informacji poufnych z Urządzenia Użytkownika.</li>
                    </ul>
                </li>
                <li> Użytkownik ma możliwość́ ograniczenia lub wyłączenia dostępu plików cookies do swojego Urządzenia. W przypadku skorzystania z tej opcji korzystanie ze Serwisu będzie możliwe, poza funkcjami, które ze swojej natury wymagają̨ plików cookies.</li>
            </ol>
            <h2>Cele w jakich wykorzystywane są cookies</h2>
            <ol>
                <li>Administrator wykorzystuje Cookies własne w celu poprawnej konfiguracji serwisu, a w szczególności do: 
                    <ul>
                        <li>a. dostosowania zawartości stron internetowych Serwisu do preferencji Użytkownika oraz optymalizacji korzystania ze stron internetowych Serwisu; </li>
                        <li>b. rozpoznania urządzenia Użytkownika Serwisu oraz jego lokalizację i odpowiednio wyświetlenia strony internetowej, dostosowanej do jego indywidualnych potrzeb;</li>
                        <li>c. zapamiętania ustawień́ wybranych przez Użytkownika i personalizacji interfejsu Użytkownika, np. w zakresie wybranego języka lub regionu, z którego pochodzi Użytkownik; </li>
                        <li>d. zapamiętania historii odwiedzonych stron w serwisie w celu rekomendacji treści; </li>
                        <li>e. rozmiaru czcionki, wyglądu strony internetowej itp.</li>
                    </ul>
                </li>
                <li>Administrator wykorzystuje Cookies własne w celu uwierzytelniania użytkownika w serwisie i zapewnienia sesji użytkownika w serwisie, a w szczególności do: 
                    <ul>
                        <li>a. utrzymania sesji Użytkownika Serwisu (po zalogowaniu), dzięki której Użytkownik nie musi na każdej podstronie Serwisu ponownie wpisywać loginu i hasła; </li>
                        <li>b. poprawnej konfiguracji wybranych funkcji Serwisu, umożliwiając w szczególności weryfikację autentyczności sesji przeglądarki; </li>
                        <li>c. optymalizacji i zwiększenia wydajności usług świadczonych przez Administratora.</li>
                    </ul>
                </li>
                <li>Administrator wykorzystuje Cookies własne w celu realizacji procesów niezbędnych dla pełnej funkcjonalności stron internetowych, a w szczególności do: 
                    <ul>
                        <li>a. dostosowania zawartości stron internetowych Serwisu do preferencji Użytkownika oraz optymalizacji korzystania ze stron internetowych Serwisu. W szczególności pliki te pozwalają̨ rozpoznać podstawowe parametry Urządzenia Użytkownika i odpowiednio wyświetlić stronę̨ internetową, dostosowaną do jego indywidualnych potrzeb;</li>
                        <li>b. poprawnej obsługi programu partnerskiego, umożliwiając w szczególności weryfikację źródeł przekierowań Użytkowników na strony internetowe Serwisu.</li>
                    </ul>
                </li>
                <li>Administrator wykorzystuje Cookies własne w celu zapamiętania lokalizacji użytkownika, a w szczególności do poprawnej konfiguracji wybranych funkcji Serwisu, umożliwiając w dostosowanie dostarczanych informacji do Użytkownika z uwzględnieniem jego lokalizacji.</li>
                <li>Administrator wykorzystuje Cookies własne w celu analiz i badań oraz audytu oglądalności, a w szczególności do tworzenia anonimowych statystyk, które pomagają̨ zrozumieć, w jaki sposób Użytkownicy Serwisu korzystają̨ ze stron internetowych Serwisu, co umożliwia ulepszanie ich struktury i zawartości.</li>
                <li>Administrator usługi wykorzystuje Cookies zewnętrzne w celu zalogowania do serwisu za pomocą̨ serwisu społecznościowego Google (administrator cookies zewnętrznego: Google Inc. z siedzibą w USA).7. Administrator usługi wykorzystuje Cookies zewnętrzne w celu popularyzacji serwisu za pomocą̨ serwisów społecznościowych plus.google.com (administrator cookies zewnętrznego: Google Inc. z siedzibą w USA).</li>
            </ol>
            <h2>Możliwości określenia warunków przechowywania lub uzyskiwania dostępu przez cookies</h2>
            <ol>
                <li>Użytkownik może samodzielnie i w każdym czasie zmienić ustawienia dotyczące plików Cookies, określając warunki ich przechowywania i uzyskiwania dostępu przez pliki Cookies do Urządzenia Użytkownika. Zmiany ustawień, o których mowa w zdaniu poprzednim, Użytkownik może dokonać za pomocą̨ ustawień przeglądarki internetowej lub za pomocą̨ konfiguracji usługi. Ustawienia te mogą̨ zostać zmienione w szczególności w taki sposób, aby blokować automatyczną obsługę̨ plików cookies w ustawieniach przeglądarki internetowej bądź informować o ich każdorazowym zamieszczeniu Cookies na urządzeniu Użytkownika. Szczegółowe informacje o możliwości i sposobach obsługi plików cookies dostępne są̨ w ustawieniach oprogramowania (przeglądarki internetowej).</li>
                <li>Użytkownik może w każdej chwili usunąć́ pliki Cookies korzystając z dostępnych funkcji w przeglądarce internetowej, której używa.</li>
                <li>Ograniczenie stosowania plików Cookies, może wpłynąć́ na niektóre funkcjonalności dostępne na stronie internetowej Serwisu.</li>
            </ol>
        </div>
    </div>
  );
}

export default Privacy;