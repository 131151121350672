import React from 'react';
import './NotFoundPage.scss';
import notFoundImage from '../../../assets/img/404.png';
import { Link } from 'react-router-dom';

function NotFoundPage({t}) {
  return (
    <div className="not-found-main-wrapper">
      <div className="not-found-content">
        <div className="not-found-image">
          <img src={notFoundImage} alt="" />
        </div>
        <div className="explanation-block">
          <div className="explanation-capture">
            {t("nopage_capture")}
          </div>
          <div className="explanation-description">
            {t("nopage_description")}
          </div>
          <Link to='/'>
            <div className="explanation-link">{t("nopage_button")}</div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default NotFoundPage;