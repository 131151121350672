import React from 'react';
import './MapBlock.scss';
import mapVideo from '../../assets/img/mapVideo.png';
import Map from '../../components/map/Map';
import "leaflet/dist/leaflet.css";
import video from '../../assets/video/video.MP4';

function MapBlock({t}) {
  return (
    <div className="map-block-wrapper">
        <div className="map-block-content">
            <div className="map-block-capture">
                {t("map_capture")}
            </div>
            <div className="map-block-description">
                {t("map_description")}
            </div>
            <div className="map-block-content-wrapper">
                <div className="map-img">
                    <video autoPlay loop muted src={video}></video>
                </div>
                <div className="map-block-map-wrapper">
                    <Map/>
                </div>
            </div>
        </div>
    </div>
  );
}

export default MapBlock;