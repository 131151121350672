import React from "react";

const questionData = (t) => { return [
    {
        question:t("question1"),
        answer: t("answer1")
    },
    {
        question:t("question2"),
        answer: t("answer2")
    },
    {
        question:t("question3"),
        answer: t("answer3")
    },
    {
        question:t("question4"),
        answer: t("answer4")
    },
    {
        question:t("question5"),
        answer: t("answer5")
    },
    {    
        question:t("question6"),
        answer: t("answer6")
    },
];
}
export default questionData;