import './LanguageSelect.scss';
import React from 'react';
import OutsideHook from '../../outsidehook/OutsideHook';
import planet from '../../../assets/img/planet.svg';
import { useState } from 'react';
import { Link, useLocation, useMatch } from 'react-router-dom';
import { generateLanguage } from '../../../i18n';
import { whitelists } from '../../../i18n';


function LanguageSelect(props) {
  
  const [ref, isShow,setIsShow] = OutsideHook(false);

  const location = useLocation();
  const match = useMatch('/:lang/*');

  let lang = match && match.params ? 
  (whitelists.includes(match.params.lang) ? match.params.lang : null) : null ;

  const disolayLang = lang ? lang : 'ua';

  const [currentLanguage, setCurrentLanguage] = useState(disolayLang.toLocaleUpperCase());

  const setLanguage = (language) => {
    setCurrentLanguage(language.toUpperCase());
    props.onLanguageChange(language.toLowerCase());
    setIsShow(false);
  }
  
  const langData = ['PL','UA','RU'];
    const langItem = langData
    .filter(item => item !== currentLanguage)
    .map(item => (
      <Link className="dropdown-item" key={item} to={generateLanguage(item.toLocaleLowerCase(), location)} onClick={()=>{
        setLanguage(item);
        }}>
          <img className='languageSelectorImage' src={planet} alt="" />
          <div className="lang-item-label" >{item}</div>
      </Link>
  ))
  return (
    <div ref ={ref} className="dropdown">
        <div className="dropdown-btn" onClick={() => {
          setIsShow(!isShow)
          }}>
          <img className='languageSelectorImage-main' src={planet} alt="" /> 
          <div className="lang-itam-label">{currentLanguage}</div>
        </div>
        {isShow && (<div className="dropdown-content">
          {langItem}
        </div>)}
    </div>
  );
}

export default LanguageSelect;