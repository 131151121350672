import React from 'react';
import './Hero.scss';
import Button from '../../components/button/Button';
import { useInView } from 'react-intersection-observer';

function Hero({t,text,handlerSelectModal}) {

    const{ ref,inView } = useInView({
        threshold:0,
      });

  return (
    <div ref={ref} className={`hero-wrapper ${inView ? 'active' : ''} `}>
        <div className="hero-conent-wrapper">
            <div className="hero-capture">
                {t("hero_capture")}
            </div>
            <div className="hero-description">
                {t("hero_description")}
            </div>
            <div className="hero-button">
                <Button text={t("Hero_button")} handlerSelectModal={handlerSelectModal}/>
            </div>
        </div>
    </div>
  );
}

export default Hero;