import React from 'react';
import Header from '../../contains/header/Header';
import Footer from '../../contains/footer/Footer';
import { Outlet } from 'react-router-dom';

function Layout(props) {
  return (
    <>
        <Header t={props.t} changeLanguage={props.changeLanguage} handlerSelectModal={props.handlerSelectModal}/>
        <Outlet/>
        <Footer t={props.t} onFormFail={props.onFormFail} onFormSuccess={props.onFormSuccess}/>
    </>
  );
}

export default Layout;