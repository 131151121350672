import React from 'react';
import burger from '../../../../assets/img/burger.png';
import close from '../../../../assets/img/headerMobClose.png';
import logo from '../../../../assets/img/Service_Logo_Kolor.svg';
import OutsideHook from '../../../outsidehook/OutsideHook';
import { useEffect,useState } from 'react';
import Button from '../../../button/Button';
import './HeaderMob.scss';
import { HashLink as Link } from 'react-router-hash-link';

function HeaderMob(props) {
    const [currentLanguage, setCurrentLanguage] = useState('PL');
    const navData = [
      {
          id:'services',
          description:props.t("nav_services")
      },
      {
          id:'rezults',
          description:props.t("nav_advantages")
      },
      {
          id:'team',
          description:props.t("nav_team")
      },
      {
          id:'advantages',
          description:props.t("nav_why_are_we")
      },
      {
        id:'b2b',
        description:props.t("nav_b2b")
    },
      {
          id:'footer',
          description:props.t("nav_contact")
      },
      ];
    const navItem = navData.map((item) => (
        <div key={item.id} className="nav-item"  onClick={()=>{
          dropMenu();
          }}><Link  to={`/#${item.id}`} smooth>{item.description}</Link></div>
    ))

    const setLanguage = (language) => {
        setCurrentLanguage(language.toUpperCase());
        props.onLanguageChange(language.toLowerCase());
        setIsShow(false);
      }

    const langData = ['PL','UA','RU'];
    const langItem = langData
    .map(item => (
      <div className={`dropdown-item ${currentLanguage === item ? "red": ''}`} key={item} onClick={()=>{
        setLanguage(item);
        
        }}>
          {item}
      </div>
  ));

  const [ref ,isShow,setIsShow] = OutsideHook(false);
  const dropMenu = () => {
      setIsShow(!isShow);
    }
    useEffect(() => {
      const html = document.querySelector("html");
      if (html) {
        html.style.overflow = isShow ? "hidden" : "auto";
      }
    }, [isShow]);

  return (
    <div className="header-main-wrapper-mob" ref={ref}>
        <div className="header-content">
            <div className="header-logo">
              <Link to='/'><img src={logo} alt="logo" /></Link>
            </div>
            <div className="drop-main-image" onClick={()=>{
            dropMenu();
            }}>
            <img src={isShow ? close : burger} alt="burger"  />
            </div>
        </div>
        {isShow && 
        <div className="drop-block">
            <div className="nav-wrapper">
                {navItem}
            </div>
            <div className="language-block-and-button-wrapper">
                <div className={`language-wrapper`}>
                    {langItem}
                </div>
                <div className="button-wrapper-mob">
                    <Button text={"Заказать звонок"} handlerSelectModal={props.handlerSelectModal}/>
                </div>
            </div>
        </div>
            
        }
    </div>
  );
}

export default HeaderMob;