import React from 'react';
import './Success.scss';
import successImg from '../../../assets/img/successImg.png';

function Success(props) {
  return (
    <div className="main-success-wrapper">
        <div className="success-content">
            <div className="success-img">
                <img src={successImg} alt="success"/>

            </div>
            <div className="success-capture">
                {props.t("success_capture")}
            </div>
            <div className="success-description">
                {props.t("success_description")}
            </div>
        </div>
    </div>
  );
}

export default Success;