import React from 'react';
import './Results.scss';
import { useInView } from 'react-intersection-observer';

function Results({t}) {
    const{ ref,inView } = useInView({
        threshold:0,
      });
    const achievements =[
        {
            capture:t("results_item_capture_1"),
            description:t("results_item_description1")
        },
        {
            capture:"4100",
            description:t("results_item_description2")
        },
        {
            capture:"90",
            description:t("results_item_description3")
        },
    ];
    const achievementsItem = achievements.map((item) => (
        <div key={item.capture} className={`achievements-item ${inView ? 'achievements-item-active' :''}`}>
            <div className='red-capture'>{item.capture}</div>
            <div className='achievements-description'>{item.description}</div>
        </div>
    ))
  return (
    <div className="rezults-wrapper">
        <div ref={ref} className="rezults-wrapper-content">
            <div  className={`rezults-text-block ${inView ? 'active': ''}`}>
                <div className="rezults-header">
                    {t("results_capture")}
                </div>
                <div className="rezults-description">
                    {t("results_description")}
                </div>
            </div>
            <div className="achievements-block-wrapper">
                {achievementsItem}
            </div>
        </div>
    </div>
  );
}

export default Results;