import React from 'react';
import './Form.scss';
import { useState, useEffect } from 'react';
import axios from "axios";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'

function Form({ t, textButton, buttonWidth, onFormSuccessSubmit, onFormFailSubmit }) {

    const [formValid, setFormvalid] = useState(false);

    const [nameError, setNameError] = useState('');
    const [telError, setTelError] = useState('');

    const [consent, setConsent] = useState(false);
    const [consentErrorState, setConsentErrorState] = useState('');
    const onToggleCheck = () => {
        setConsent(!consent);
        setConsentErrorState('');
    }

    const [name, setName] = useState('');
    const setNameHandler = (event) => {
        setName((prev) => {
            prev = event.target.value;
            if (prev.length <= 1) {
                setNameError('red')
            } else setNameError('')
            return prev;
        });
    };

    /*const [tel, setTel] = useState('');
    const setTelHandler = (event) => {
        setTel((prev) => {
            prev = event.target.value;
            if (prev.length <= 1) {
                setTelError('red')
            } else setTelError('')
            return prev;
        });
    };*/
    const [phoneValue, setPhoneValue] = useState('');
    const setPhoneHandler = (value) => {
        setPhoneValue((prev) => {
            prev = value;
            if (prev <= 5) {
                setTelError('red')
            } else setTelError('')
            return prev;
        });
    };

    const [phoneViberValue, setPhoneViberValue] = useState('');
    const setPhoneViberHandler = (event) => {
        setPhoneViberValue((prev) => {
            prev = event.target.value;
            if (prev <= 5) {
                setTelError('red')
            } else setTelError('')
            return prev;
        });
    };

    const [valueMask, setValueMask] = useState('');
    const onChangeValue = (e) => {
        setValueMask(e);
        setPhoneHandler(e);

    }

    const [mail, setMail] = useState('');
    const [mailError, setMailError] = useState('')

    const setMailHandler = (event) => {
        setMail((prev) => {
          prev = event.target.value;
          const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
          if (!re.test(String(prev).toLocaleLowerCase()) && prev.length !== 0) {
            setMailError('red')
          } else
            setMailError('')
          return prev;
        });
      };

    useEffect(() => {
        if ((nameError === 'red' || name.length === 0) || (telError === 'red') || (mail === 'red')) {
            setFormvalid(false)
        } else {
            setFormvalid(true)
        }
    }, [nameError, name.length, telError,mail]);


    const onClickSendButtonHandler = () => {
        if (!consent) {
            setConsentErrorState('red');
        }
        if (formValid && consent) {
            try {
                axios.get(`https://3k6xhpvbf6.execute-api.eu-west-2.amazonaws.com/default/asariProxy?name=${name}&phone=${phoneValue}&viberPhone=${phoneViberValue}&email=${mail}`);
                onFormSuccessSubmit();
            } catch (error) {
                console.error('Failed to create lead:', error);
                onFormFailSubmit();
            }
        } else console.log("????")
    }
    return (
        <div className="form-wrapper">
            <form className='contact-form' method='post' onSubmit={(e) => e.preventDefault()}>
                <div id="form-inner">
                    <div className="form-input">
                        <input type="text" className={`${'input'} ${nameError}`}
                            onChange={setNameHandler}
                            id="name"
                            value={name}
                            placeholder={t("form_placeholder_name")} />
                    </div>
                    <div className={`${"phone-wrapper"} ${telError}`}>
                        <PhoneInput className='input-mask'
                            international
                            countryCallingCodeEditable={false}
                            defaultCountry="PL"
                            value={valueMask}
                            onChange={onChangeValue}
                        />
                    </div>
                    <div className="form-input">
                        <input type="number" className={`${'input'} ${telError}`}
                            onChange={setPhoneViberHandler}
                            id="viberTel"
                            value={phoneViberValue}
                            placeholder={t("form_placeholder_tel_viber")} />
                    </div>
                    <div className="form-input">
                        <input type="email" className={`${'input'} ${mailError}`}
                            onChange={setMailHandler}
                            id="email"
                            value={mail}
                            placeholder={'Email'} />
                    </div>

                    <div className={`form-bottom ${consentErrorState}`}>
                        <div><input className='checkbox' checked={consent} onChange={onToggleCheck} type="checkbox" id='check' /></div>
                        <div className={`form-description `}>
                            {t("form_checkbox_text")}
                        </div>
                    </div>
                    <div className={"contact-button"} style={buttonWidth}>
                        <button disabled={!formValid && !consent} type="submit" className="form-button" onClick={() => {
                            onClickSendButtonHandler();
                        }
                        } >{textButton}</button>
                    </div>
                </div>

            </form>
        </div>
    );
}

export default Form;