import React, { useRef } from 'react';
import './Reviews.scss';
import rev1 from '../../assets/img/rev1.svg';
import rev2 from '../../assets/img/rev2.svg';
import rev3 from '../../assets/img/rev3.svg';
import rev4 from '../../assets/img/rev4.svg';
import Slider from 'react-slick';
import './slick.css';
import './slick-theme.css'
/*import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";*/
import arrowGrey from '../../assets/img/arrowPrevGrey.png';
import arrowRed from '../../assets/img/arrowNextRed.png';
import { useInView } from 'react-intersection-observer';

function Reviews({ t }) {
    const { ref, inView } = useInView({
        threshold: 0,
    });
    const settings = {
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1026,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 718,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
        ]
    };

    const revData = [
        {
            text: t("reviews_data_tex1"),
            img: rev1,
            BottomCapture: t("reviews_data_bottom_capture1")
        },
        {
            text: t("reviews_data_tex2"),
            img: rev2,
            BottomCapture: t("reviews_data_bottom_capture2")
        },
        {
            text: t("reviews_data_tex3"),
            img: rev3,
            BottomCapture: t("reviews_data_bottom_capture3")
        },
        {
            text: t("reviews_data_tex4"),
            img: rev4,
            BottomCapture: t("reviews_data_bottom_capture4")
        }
    ];

    const revItem = revData.map((item, index) => (
        <div key={index} className="item">
            <div className="rev-slide-item" style={{ width: 360 }}>
                <div className="rev-text">
                    {item.text}
                </div>
                <div className="absolute-content">
                    <div className="rev-img">
                        <img src={item.img} alt="" />
                    </div>
                    <div className="rev-bottom-capture">
                        {item.BottomCapture}
                    </div>
                </div>

            </div>
        </div>

    ))


    const inputRef = useRef(null);
    const next = () => {
        inputRef.current.slickNext();
    }
    const prev = () => {
        inputRef.current.slickPrev();
    }
    return (
        <div className="rev-wrapper">
            <div ref={ref} className={`rev-content ${inView ? 'rev-content-active' : ''}`}>
                <div className="rev-capture">
                    {t("reviews_capture")}
                </div>
                <div className="rev-slide-block">
                    <Slider ref={inputRef} {...settings}>
                        {revItem}
                    </Slider>
                    <div className="slider-button-prev">
                        <button onClick={prev}><img src={arrowGrey} alt="" /> {t("reviews_button_back")}</button>
                    </div>
                    <div className="slider-button-next">
                        <button onClick={next}>{t("reviews_button_next")} <img src={arrowRed} alt="" /></button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Reviews;