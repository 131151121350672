import React from 'react';
import { MapContainer, TileLayer, Marker ,Popup} from 'react-leaflet';
import './Map.scss';
import "leaflet/dist/leaflet.css";
import logo from '../../assets/img/header_logo.png';




function Map() {
  React.useEffect(() => {
    const L = require("leaflet");

    delete L.Icon.Default.prototype._getIconUrl;

    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png")
    });
  }, []);
    const position = [51.097405042442155, 17.036076622010167]
  return (
    <MapContainer style={{ height:580 }} center={position} zoom={13.1} scrollWheelZoom={false}>
  <TileLayer
    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
  />
  <Marker position={position}>
    <Popup>
      <div className="map-logo-position">
        <img src={logo} alt="logo" />
      </div>
    </Popup>
  </Marker>
</MapContainer>
  );
}

export default Map;