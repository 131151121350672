import React from "react";

const servicesData = (t) => { return [
    {
        capture:t("services_data_capture1"),
        explanation:t("services_data_explanation1"),
        base:[t("services_data_Base1_1"),t("services_data_Base1_2"),t("services_data_Base1_3")],
        price:"Oferta specjalna- prosimy o kontakt"
    },
    {
        capture:t("services_data_capture2"),
        explanation:t("services_data_explanation2"),
        base:[t("services_data_Base2_1"),t("services_data_Base2_2"),t("services_data_Base2_3")],
        price:"1 650 zł"
    },
    {
        capture:t("services_data_capture3"),
        explanation:t("services_data_explanation3"),
        base:[t("services_data_Base3_1"),t("services_data_Base3_2"),t("services_data_Base3_3")],
        price:"1 650 zł"
    },
    {
        capture:t("services_data_capture4"),
        explanation:"",
        base:[t("services_data_Base4_1"),t("services_data_Base4_2"),t("services_data_Base4_3")],
        price:"2 000 zł"
    },
    {
        capture:t("services_data_capture5"),
        explanation:"",
        base:[t("services_data_Base5_1"),t("services_data_Base5_2"),t("services_data_Base5_3")],
        price:"2 000 zł"
    },
    {
        capture:t("services_data_capture6"),
        explanation:t("services_data_explanation6"),
        base:[t("services_data_Base6_1"),t("services_data_Base6_2"),t("services_data_Base6_3")],
        price:"2 000 zł"
    },
    {
        capture:t("services_data_capture7"),
        explanation:t("services_data_explanation7"),
        base:[t("services_data_Base7_1"),t("services_data_Base7_2"),t("services_data_Base7_3")],
        price:"2 000 zł"
    },
    {
        capture:t("services_data_capture8"),
        explanation:"",
        base:[t("services_data_Base8")],
        price:"615  zł"
    },
    {
        capture:"PESEL",
        explanation:"",
        base:[t("services_data_Base9")],
        price:"250  zł"
    },
    {
        capture:t("services_data_capture10"),
        explanation:"",
        base:[t("services_data_Base10_1"),t("services_data_Base10_2"),t("services_data_Base10_3")],
        price:"615  zł"
    },
    {
        capture:t("services_data_capture11"),
        explanation:"",
        base:[t("services_data_Base11")],
        price:t("services_data_price11")
    },
    {
        capture:t("services_data_capture12"),
        explanation:"",
        base:[t("services_data_Base12")],
        price:"950 zł"
    },
    {
        capture:t("services_data_capture13"),
        explanation:"",
        base:[t("services_data_Base13")],
        price:"150  zł"
    },
    {
        capture:t("services_data_capture14"),
        explanation:"",
        base:[t("services_data_Base14")],
        price:t("services_data_price14")
    },
    {
        capture:t("services_data_capture15"),
        explanation:"",
        base:[t("services_data_Base15")],
        price:t("services_data_price15")
    },
    {
        capture:t("services_data_capture16"),
        explanation:"",
        base:[t("services_data_Base16_1"),t("services_data_Base16_2")],
        price:"500  zł"
    },
    {
        capture:t("services_data_capture17"),
        explanation:"",
        base:[t("services_data_Base17")],
        price:"1500  zł"
    },
    {
        capture:t("services_data_capture18"),
        explanation:"",
        base:[t("services_data_Base18")],
        price:t("services_data_price18")
    },
    {
        capture:t("services_data_capture19"),
        explanation:"",
        base:[t("services_data_Base19_1"),t("services_data_Base19_2"),t("services_data_Base19_3")],
        price:"300  zł"
    },
]
};
export default servicesData;