import React from 'react';
import './Clients.scss';
import clientsLink1 from '../../assets/img/service.svg';
import clientsLink2 from '../../assets/img/clientsLink2.svg';
import clientsLink3 from '../../assets/img/medic.svg';

function Clients({t}) {
    const clientsData = [
        {
            img:clientsLink1,
            link:"https://personnelservice.pl/"
        },
        {
            img:clientsLink2,
            link:"https://personnelsupport.pl/"
        },
        {
            img:clientsLink3,
            link:"https://recruitment.personnelmedic.pl/"
        }];
    const clientsItem = clientsData.map((item,index) =>(
        <div key={index}  className="clients-item-link">
            <a href={item.link} target='blank'> <img src={item.img}alt="image-link" /></a>
        </div>
    ))
  return (
    <div className="clients-wrapper" id='b2b'>
        <div className="clients-wrapper-content" >
            <div className="clients-text-block">
                <div className="clients-header">
                    {t("clients_capture")}
                </div>
            </div>
            <div className="clients-block-wrapper">
                {clientsItem}
            </div>
        </div>
    </div>
  );
}

export default Clients;