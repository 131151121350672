import React from 'react';
import './Services.scss';
import ServicesItem from '../../components/services-components/ServicesItem';
import arrowUp from '../../assets/img/arrowUp.png';
import arrowDown from '../../assets/img/arrowDown.png';
import { useState } from 'react';
import servicesData from '../../components/services-components/servicesData';
import { useInView } from 'react-intersection-observer';

function Services({t,handlerSelectModal}) {
    const{ ref,inView } = useInView({
        threshold:0,
      });

    const[active,setActive]= useState();
    const onToggleCheckServices = (event) => {
        setActive((prev)=>{
            prev =!active;
            return prev
        });  
    }

  return (
    <div ref={ref} id='services' className={`services-wrapper ${inView ? 'active' : ''}`}>
        <div className="servicer-wrapper-content">
            <div className="services-header-wrapper">
                <div className="services-header-capture">
                    {t("services_main_capture")}
                </div>
                <div className="services-header">
                    <div className="services-item">
                         {t("services_main_item_description1")}
                    </div>
                    <div className="services-item">
                        {t("services_main_item_description2")}
                    </div>
                    <div className="services-item">
                        {t("services_main_item_description3")}
                    </div>
                </div>
            </div>
            <div  className={`services-items-wrapper ${active ? 'servicer-wrapper-content-active': ""}`}>
                <ServicesItem t={t} handlerSelectModal={handlerSelectModal} data={servicesData}/>
            </div>
            <div className="services-cover-part-wrapper">
                <div className={`services-cover-content ${active ? 'services-cover-content-active' : ''}`} onClick={onToggleCheckServices}>
                    {active ? (<div className="cover">
                        {t("services_main_cover_button1")}
                    </div>) : (<div className="cover">
                        {t("services_main_cover_button2")}
                    </div>)}
                    
                    <div id='rezults' className="arrows">
                        <img src={active ? arrowUp : arrowDown} alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Services;