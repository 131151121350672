import React from 'react';
import './App.scss';
import { useState } from 'react';
import Modal from '../../components/modal/Modal';
import PopUpForm from '../../components/modal/modal-popUp/PopUpForm';
import Success from '../../components/form/success/Success';
import { Routes,Route, Link } from 'react-router-dom';
import Layout from '../../components/layout/Layout';
import HomePage from '../mainpages/homepage/HomePage';
import Privacy from '../mainpages/privacy/Privacy';
import NotFoundPage from '../mainpages/404/NotFoundPage';
import { useTranslation } from "react-i18next";
import {Helmet, HelmetProvider } from 'react-helmet-async';
import '../../i18n';

function App() {
  const { t, i18n } = useTranslation();
  
  const changeLanguages = (language) => {
    i18n.changeLanguage(language);
  };

  const [modalActive, setModalActive] = useState(false);

  const handlerSelectModal = () => {
    setModalActive(!modalActive);
  };

  const closeModal = () => {
    setModalActive(false)
  }

  const modalContentForm = 'form';
  const modalContentSuccess = 'success';
  const modalContentFail = 'fail';
  const [currentModalContent, setCurrentModalContent] = useState(modalContentForm);

  let modalContent = <PopUpForm t={t} handlerSelectModal={handlerSelectModal}/>;

  const onFormSuccess = () => {
    setModalActive(true);
    setCurrentModalContent(modalContentSuccess);
  }

  const onFormFail = () => {
    // TODO fail form
  }

  switch(currentModalContent) {
    case modalContentFail:
      //todo fail
      break;
    case modalContentSuccess:
      modalContent = <Success t={t} />
        break;
      default: 
        modalContent = <PopUpForm t={t} handlerSelectModal={handlerSelectModal} onFormFail={onFormFail} onFormSuccess={onFormSuccess}/>;
  }

  return (
    
    <HelmetProvider>
      <div className="app">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Personal Legal Service</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <div className="global-content-wrapper">
        <Routes>
          <Route path='/:lang?' element={<Layout t={t} changeLanguage={changeLanguages} handlerSelectModal={handlerSelectModal} onFormFail={onFormFail} onFormSuccess={onFormSuccess}/>}>
            <Route index element={<HomePage t={t} handlerSelectModal={handlerSelectModal} onFormFail={onFormFail} onFormSuccess={onFormSuccess}/>}/>
            <Route path='privacy' element={<Privacy/>}/>
            <Route path='*' element={<NotFoundPage t={t}/>}/>
          </Route>
        </Routes>
        <Modal setActive={handlerSelectModal} active={modalActive} handlerSelectModal={
        () => {
          closeModal();
          setCurrentModalContent(modalContentForm)
        }}>
          {modalContent}
        </Modal>
      </div>
    </div>
    </HelmetProvider>
    
  );
}

export default App;
